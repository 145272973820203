<template>
  <div class="m-auto mx-4 p-4 my-4 mt-0 pt-0 flex-grow">
    
    <h1 class="mb-6 text-xl text-center text-gray-600">بيانات التعاقد و التأشيرة</h1>
    
    <div class="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

      <div class="sm:col-span-2">
        <label for="last-name" class="block text-sm font-medium text-gray-700">
          رقم التأشيره
        </label>
        <div class="mt-1">
          <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="last-name" class="block text-sm font-medium text-gray-700">
          رقم الهاتف
        </label>
        <div class="mt-1">
          <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          هاتف 2
        </label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          جهة العمل
        </label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          هاتف جهة العمل
        </label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="email" class="block text-sm font-medium text-gray-700">
          عنوان جهة العمل
        </label>
        <div class="mt-1">
          <input id="text" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="email" class="block text-sm font-medium text-gray-700">
          الوظيفة المرشح لها
        </label>
        <div class="mt-1">
          <input id="text" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>
      
    </div>

    <vue-recaptcha class="mt-4" sitekey="6LdJnSAeAAAAAHdaNTIXHlDaJilKR6l4J4Cc8DcN"></vue-recaptcha>

  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
//site key  :6LdJnSAeAAAAAHdaNTIXHlDaJilKR6l4J4Cc8DcN
//secret key:6LdJnSAeAAAAADa9-O30MEaL-wje6XXll7HTuaTl

export default {
  components: { VueRecaptcha }
}
</script>

<style>

</style>